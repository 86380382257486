import { post } from "@/method/http"
import { ElMessage } from "element-plus"
import { defineStore } from "pinia"
import { ref } from "vue"



export const userStore = defineStore('user', () => {
  const cookie = ref(0)
  const user_account = ref('')
  const token = ref('')
  const userInfo = {
    accountInfo: {} as any,
    employeeInfo: {} as any,
    roles: [] as any,
  }

  async function login(account: string, password: string, sys: boolean) {
    //读取cookie
    const cookieStr = document.cookie
    const cookieArr = cookieStr.split(';')
    cookieArr.forEach(item => {
      const arr = item.split('=')
      if (arr[0] == 'cookie') {
        cookie.value = Number(arr[1])
      } else if (arr[0] == 'account') {
        user_account.value = arr[1]
      } else if (arr[0] == 'token') {
        token.value = arr[1]
      }
    })
    const loginRes = await post<any>('/auth/login', { username: account, password: password, sys: sys, token: token.value })
    if (loginRes.token) {
      cookie.value = new Date().getTime()
      document.cookie = `cookie=${cookie.value}`
      document.cookie = `account=${account}`
      document.cookie = `token=${loginRes.token}`
      document.cookie = `userInfo=${JSON.stringify(loginRes.userInfo)}`

      userInfo.accountInfo = loginRes.userInfo.accountInfo
      userInfo.employeeInfo = loginRes.userInfo.employeeInfo
      userInfo.roles = loginRes.userInfo.roles

      return loginRes
    }
  }
  //延长cookie有效期
  function extendCookie() {
    cookie.value = new Date().getTime()
    document.cookie = `cookie=${cookie.value}`
  }
  function checkAuth() {
    const time = new Date().getTime()
    //读取cookie
    const cookieStr = document.cookie

    const cookieArr = cookieStr.split(';')
    cookieArr.forEach(item => {
      const arr = item.split('=')
      if (arr[0].includes('cookie')) {
        cookie.value = Number(arr[1])
      } else if (arr[0].includes('account')) {
        if (arr[1]?.length) user_account.value = arr[1]
      } else if (arr[0].includes('token')) {
        if (arr[1]?.length) token.value = arr[1]
      } else if (arr[0].includes('userInfo')) {
        if (arr[1]?.length) {
          const info = JSON.parse(arr[1])
          userInfo.accountInfo = info?.accountInfo
          userInfo.employeeInfo = info?.employeeInfo
          userInfo.roles = info?.roles
        }
      }
    })
    if (time - cookie.value < 1000 * 60 * 15) {
      extendCookie()
      return true
    } else {
      return false
    }
  }
  function exit() {
    cookie.value = 0
    document.cookie = `cookie=${cookie.value}`
    document.cookie = `account=`
    document.cookie = `token=`
    ElMessage.success('已注销')
  }

  return { cookie, login, checkAuth, userInfo, exit }
})