import { userStore } from '@/store'
import { ElMessage } from 'element-plus'
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
// const roleSet = [
//   //个人管理，房源管理，客源管理，交易管理，人事管理，楼盘管理，系统管理，知识库
//   { code: 1, name: "PersonManager" },
//   { code: 2, name: "HouseManager" },
//   { code: 3, name: "CustomerManager" },
//   { code: 4, name: "TransactionManager" },
//   { code: 5, name: "HrmManager" },
//   { code: 6, name: "BuildingManager" },
//   { code: 7, name: "SystemManager" },
//   { code: 8, name: "KnowledgeManager" },
//   //个人管理 
//   //人事管理：开账号，部门与职员
//   { code: 51, name: "AccountCreate" },
//   { code: 52, name: "DepartureEmployee" },
// ]
export const menu = [
  {
    group: '个人管理',
    icon: 'postcard',
    role: ['PersonManager'],
    items: [
      {
        name: '待办事项',
        path: '/a',
        compoment: import('../views/self/TodoList.vue')
      },
      {
        name: '个人资料',
        path: '/b',
        compoment: import('../views/self/EditPersonInfo.vue')
      },
      {
        name: '我的收藏',
        path: '/c',
        compoment: import('../views/EmptyView.vue')
      },
      {
        name: '修改密码',
        path: '/d',
        compoment: import('../components/form/ResetPassword.vue')
      }
    ]
  },
  {
    group: '房源管理',
    icon: 'house',
    role: ['HouseManager'],
    items: [
      {
        name: '房源录入',
        path: '/e',
        compoment: import('../views/house/HouseImport.vue')
      },
      {
        name: '出售房源',
        path: '/f',
        compoment: import('../views/house/HouseSale.vue')
      },
      {
        name: '出租房源',
        path: '/g',
        compoment: import('../views/EmptyView.vue')
      }
    ]
  },
  {
    group: '客源管理',
    role: ['CustomerManager'],
    icon: 'phone',
    items: [
      {
        name: '客源录入',
        path: '/h',
        compoment: import('../views/EmptyView.vue')
      },
      {
        name: '客源管理',
        path: '/i',
        compoment: import('../views/EmptyView.vue')
      }
    ]
  },
  {
    group: '交易管理',
    icon: 'money',
    role: ['TransactionManager'],
    items: [
      {
        name: '合同管理',
        path: '/j',
        compoment: import('../views/deal/DealManage.vue')
      }
    ]
  },
  {
    group: '人事管理',
    icon: 'user',
    role: ['HrmManager'],
    items: [
      {
        name: '开账号',
        path: '/k',
        role: ['AccountCreate'],
        compoment: import('../views/hrm/CreateSubaccount.vue')
      },
      {
        name: '部门与职员',
        path: '/l',
        role: ['DepartureEmployee'],
        compoment: import('../views/hrm/EmployeeView.vue')
      }
    ]
  },
  {
    group: '楼盘管理',
    icon: 'setUp',
    role: ['BuildingManager'],
    items: [
      {
        name: '楼盘管理',
        path: '/building',
        compoment: import('../views/building/BuildingManager.vue')
      },
      {
        name: '商圈管理',
        path: '/n',
        compoment: import('../views/EmptyView.vue')
      },
      {
        name: '商圈地图',
        path: '/o',
        compoment: import('../views/EmptyView.vue')
      }
    ]
  },
  {
    group: '系统管理',
    icon: 'setUp',
    role: ['SystemManager'],
    items: [
      {
        name: '字典管理',
        path: '/dictionary',
        compoment: import('../views/system/DictionaryView.vue')
      }
    ]
  },
  {
    group: '知识库',
    icon: 'collection',
    role: ['KnowledgeManager'],
    items: [
      {
        name: '附近商圈',
        path: '/p',
        compoment: import('../views/EmptyView.vue')
      },
      {
        name: '交易完全手册',
        path: '/q',
        compoment: import('../views/EmptyView.vue')
      },
      {
        name: '帮助',
        path: '/r',
        compoment: import('../views/EmptyView.vue')
      },
      {
        name: '开发API',
        path: '/s',
        compoment: import('../views/EmptyView.vue')
      }
    ]
  },
]
const routes: Array<RouteRecordRaw> = [
  { path: '/', component: () => import('../views/HomeView.vue') },
  { path: '/login', component: () => import('../views/LoginView.vue') },
]
menu.forEach((item) => {
  item.items.forEach((item) => {
    const r = {
      path: item.path,
      name: item.name,
      component: () => item.compoment,
      meta: {
        keepAlive: true
      }
    }
    routes.push(r)
  })
})

//路由守卫

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from) => {
  if (to.path == '/login') {
    return true
  }
  else if (userStore().checkAuth()) {
    return true;
  } else {
    ElMessage.error("您已经退出系统");
    return { path: '/login', replace: true }
  }
})
