import { userStore } from "@/store";
import axios from "axios";
import { ElMessage } from "element-plus";

// axios.defaults.withCredentials = true;
// const baseURL = "https://mobile.51fubaba.cn:8443/crm";
const baseURL = "http://121.196.237.175:39444";
type anyObject = { [key: string]: PropertyKey | null };
export async function get<R>(url: string, params?: anyObject | boolean): Promise<R> {
    if (!userStore().checkAuth() && !url.includes("/auth")) {
        ElMessage.error("登录已过期，请刷新");
    }
    try {
        const result = (await axios.get(baseURL + url, { params })).data;
        return result;
    } catch (e) {
        const result = e as { message: string }
        ElMessage.error(JSON.stringify("服务错误，请稍后重试"));
        return {} as R;
    }
}

export async function post<R>(url: string, data?: any): Promise<R> {
    if (!userStore().checkAuth()) {
        if (url != "/auth/login" && url != "/auth/code") {
            ElMessage.error("会话已过期，请刷新");
        }
    }
    try {
        const result = (await axios.post(baseURL + url, data)).data;
        if (result.code != 0) {
            if (result.message && result.message.length > 2) {
                ElMessage.error(result.message);
            }
        } else {
            ElMessage.success("操作成功");
        }
        return result;
    } catch (e) {
        const result = e as { message: string }
        ElMessage.error(JSON.stringify("服务错误，请稍后重试"));
        return {} as R;
    }
}